import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withPage } from "../../Page";
import ActionCreator from "../../ActionCreator";
import ItemsList from "../../Components/BlogItemsList";
import BreadCrumbs from "../../Components/BlogBreadCrumbs";
import DetailView from "../../Components/BlogDetailView";

class BlogDetailPage extends React.Component {
  render() {
    let { blogs, blog } = this.props.pageContext;

    return (
      <Wrapper>
        <div className="items-right">
          <ItemsList blogs={blogs} />
        </div>
        <div className="items-content">
          <div className="items-toolbar">
            <BreadCrumbs />
          </div>

          <div className="detail-view">
            <DetailView data={blog} />
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  max-width: 1260px;
  width: 100%;
  background-color: white;
  margin: 0 auto;
  padding: 5% 10px;

  & > .items-right {
    max-width: 300px;
    width: 100%;
  }

  & > .items-content {
    max-width: 819px;
    width: 100%;

    & .items-toolbar {
      margin-bottom: 36px;
    }

    & .detail-view {
    }
  }

  @media screen and (max-width: 1024px) {
    justify-content: center;
    padding: 5%;
    & > .items-right {
      display: none;
    }
  }
`;

export default withPage(
  connect(
    null,
    ActionCreator
  )(BlogDetailPage)
);
