import React, { Component } from "react";
import styled from "styled-components";
import { mdy } from "../Utils/DateUtil";

class BlogDetailView extends Component {
  render() {
    let { data } = this.props;
    return (
      <Wrapper img={data.thumbnail}>
        <div className="title">{this._limitWords(data.title)}</div>
        <div className="subtitle">{mdy(data.date)}</div>
        <div className="image" />
        <div
          className="html-view"
          dangerouslySetInnerHTML={{ __html: data.html }}
        />
      </Wrapper>
    );
  }

  _limitWords = words => {
    if (words.length >= 40) {
      return words.slice(0, 41);
    }
    return words;
  };
}

const Wrapper = styled.div`
  width: 100%;

  & > .title {
    margin-bottom: 8px;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 20px;
    color: #0a6fb7;
  }

  & > .subtitle {
    margin-bottom: 34px;
    white-space: pre-wrap;
    line-height: 20px;
    font-size: 14px;
    color: #b4b9c4;
  }

  & > .image {
    margin-bottom: 22px;
    width: 100%;
    height: 475px;

    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  & > .html-view {
    width: 100%;
    line-height: 30px;
    font-size: 14px;
    color: #16233d;
    overflow: hidden;

    img {
      width: 100%;
    }
    iframe {
      width: 100%;
      height: 475px;
    }
  }

  @media screen and (max-width: 550px) {
    & > .image {
      height: 251px;
    }
    & > .html-view {
      iframe {
        height: 251px;
      }
    }
  }
`;

export default BlogDetailView;
